import React, { useState } from "react";
import axios from "axios";
import { handleSendTextAlert } from "../utils/helpers";

const Footer = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phone: '',
    stateOfIncorporation: '',
    yearOfIncorporation: '',
    ficoScore: '',
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(),  // Trim value to remove extra spaces
    }));
  };

  // Validate fields before submission
  const validateFields = () => {
    const errors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Basic field checks
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required.';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required.';
    }

    if (!formData.businessName.trim()) {
      errors.businessName = 'Business name is required.';
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required.';
    } else if (!phoneRegex.test(formData.phone)) {
      errors.phone = 'Invalid phone number. Please enter a 10-digit number.';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required.';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format.';
    }

    if (!formData.stateOfIncorporation) {
      errors.stateOfIncorporation = 'State of incorporation is required.';
    }

    if (!formData.yearOfIncorporation) {
      errors.yearOfIncorporation = 'Year of incorporation is required.';
    }

    if (!formData.ficoScore) {
      errors.ficoScore = 'FICO score is required.';
    }

    setValidationErrors(errors);
    console.log('Validation Errors:', errors);
    
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmitForm = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setValidationErrors({});

    const isValid = validateFields();
    console.log(isValid);
    
    if (!isValid) {
      return;  // If not valid, stop form submission
    }

    setLoading(true);

    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`;

    const hubspotFormData = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'company', value: formData.businessName },
        { name: 'email', value: formData.email },
        { name: 'phone', value: formData.phone },
        { name: 'state_of_incorporation', value: formData.stateOfIncorporation },
        { name: 'start_year', value: formData.yearOfIncorporation },
        { name: 'credit_score', value: formData.ficoScore },
      ],
    };

    try {
      await axios.post(HUBSPOT_ENDPOINT, hubspotFormData);

      await handleSendTextAlert(formData);

      setLoading(false);
      alert('Form submitted successfully!');
      setFormData({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
      });
    } catch (error) {
      console.error('Network error:', error);
      setLoading(false);
    }
  };

  return (
    <footer className="bg-white py-10">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          {/* Logo and Hours of Operation */}
          <div className="col-span-1">
            <a href="/" className="flex items-center">
              <img
                src="https://akfsi.com/static/media/akfsi-logo-black.89678b5ba0a5b9122732.png"
                alt="AKFSI Logo"
                className="h-10"
              />
            </a>
            <div className="mt-4">
              <p className="text-gray-600 font-medium">Hours of Operation</p>
              <p className="text-gray-500">Mon - Fri: 6:00AM - 5:00PM PST</p>
              <p className="text-gray-500">Closed on Weekends</p>
            </div>
          </div>

          {/* Menu Links */}
          <div className="col-span-1">
            <h4 className="text-lg font-semibold text-gray-700">Menu</h4>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="/" className="text-blue-600 hover:text-blue-800">
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/sba-loans"
                  className="text-gray-600 hover:text-blue-800"
                >
                  SBA Loans
                </a>
              </li>
              <li>
                <a
                  href="/lines-of-credit"
                  className="text-gray-600 hover:text-blue-800"
                >
                  Lines of Credit
                </a>
              </li>
              <li>
                <a
                  href="/equipment-financing"
                  className="text-gray-600 hover:text-blue-800"
                >
                  Equipment Financing
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  className="text-gray-600 hover:text-blue-800"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Request a Call Back */}
          <div className="col-span-2">
            <h4 className="text-lg font-semibold text-gray-700">
              Request a Call Back
            </h4>
            <form onSubmit={handleSubmitForm} className="space-y-4 mt-4">
            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
              {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="businessName"
                placeholder="Business Name"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
              {validationErrors.businessName && <p className="text-red-500">{validationErrors.businessName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationErrors.phone && <p className="text-red-500">{validationErrors.phone}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="stateOfIncorporation"
                value={formData.stateOfIncorporation}
                onChange={handleChange}
                required
              >
                <option value="default">Select State</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
              {validationErrors.stateOfIncorporation && <p className="text-red-500">{validationErrors.stateOfIncorporation}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="yearOfIncorporation"
                value={formData.yearOfIncorporation}
                onChange={handleChange}
                required
              >
                <option value="default">Year of Incorporation</option>
                {
                  Array.from({ length: 2024 - 1990 + 1 }, (_, i) => (
                    <option key={2024 - i} value={2024 - i}>
                      {2024 - i}
                    </option>
                  ))
                }
              </select>
              {validationErrors.yearOfIncorporation && <p className="text-red-500">{validationErrors.yearOfIncorporation}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="ficoScore"
                value={formData.ficoScore}
                onChange={handleChange}
                required
              >
                <option value="">Select FICO Score</option>
                <option value="550-639">550-639</option>
                <option value="640-699">640-699</option>
                <option value="700-749">700-749</option>
                <option value="750+">750+</option>
              </select>
              {validationErrors.ficoScore && <p className="text-red-500">{validationErrors.ficoScore}</p>}
            </div>

            <button
              className="bg-blue-600 text-white p-2 rounded-md w-full"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-10 text-center text-gray-500">
          <p>
            Copyright © {new Date().getFullYear()} AKFSI. All Rights Reserved.{" "}
            <a href="/privacy.html" className="text-blue-600 hover:underline">
              Privacy Policy
            </a>{" "}
            |{" "}
            <a
              href="/terms.html"
              className="text-blue-600 hover:underline"
            >
              Terms & Conditions
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
