import React, { useState } from 'react';
import axios from 'axios'; // Make sure you have axios installed
import HeaderImage from '../assets/sba-loans-bg.jpg';
import { handleSendTextAlert } from '../utils/helpers';

const SBAFundingPage = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    phone: '',
    stateOfIncorporation: '',
    yearOfIncorporation: '',
    ficoScore: '',
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.trim(),  // Trim value to remove extra spaces
    }));
  };

  // Validate fields before submission
  const validateFields = () => {
    const errors = {};
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Basic field checks
    if (!formData.firstName.trim()) {
      errors.firstName = 'First name is required.';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last name is required.';
    }

    if (!formData.businessName.trim()) {
      errors.businessName = 'Business name is required.';
    }

    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required.';
    } else if (!phoneRegex.test(formData.phone)) {
      errors.phone = 'Invalid phone number. Please enter a 10-digit number.';
    }

    if (!formData.email.trim()) {
      errors.email = 'Email is required.';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Invalid email format.';
    }

    if (!formData.stateOfIncorporation) {
      errors.stateOfIncorporation = 'State of incorporation is required.';
    }

    if (!formData.yearOfIncorporation) {
      errors.yearOfIncorporation = 'Year of incorporation is required.';
    }

    if (!formData.ficoScore) {
      errors.ficoScore = 'FICO score is required.';
    }

    setValidationErrors(errors);
    console.log('Validation Errors:', errors);
    
    // Return true if there are no errors
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmitForm = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setValidationErrors({});

    const isValid = validateFields();
    console.log(isValid);
    
    if (!isValid) {
      return;  // If not valid, stop form submission
    }

    setLoading(true);

    const HUBSPOT_ENDPOINT = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_PORTAL_ID}/${process.env.REACT_APP_FORM_GUID}`;

    const hubspotFormData = {
      fields: [
        { name: 'firstname', value: formData.firstName },
        { name: 'lastname', value: formData.lastName },
        { name: 'company', value: formData.businessName },
        { name: 'email', value: formData.email },
        { name: 'phone', value: formData.phone },
        { name: 'state_of_incorporation', value: formData.stateOfIncorporation },
        { name: 'start_year', value: formData.yearOfIncorporation },
        { name: 'credit_score', value: formData.ficoScore },
      ],
    };

    try {
      await axios.post(HUBSPOT_ENDPOINT, hubspotFormData);

      await handleSendTextAlert(formData);

      setLoading(false);
      alert('Form submitted successfully!');
      setFormData({
        firstName: '',
        lastName: '',
        businessName: '',
        email: '',
        phone: '',
        stateOfIncorporation: '',
        yearOfIncorporation: '',
        ficoScore: '',
      });
    } catch (error) {
      console.error('Network error:', error);
      setLoading(false);
    }
  };

  return (
    <div className="mt-[50px] min-h-screen bg-gray-50">
      {/* Main Container */}
      <div className="max-w-full mx-auto">
        
        {/* Title Section */}
        <div 
          className={`relative bg-cover bg-top h-[500px]`}
          style={{ backgroundImage: `url(${HeaderImage})` }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <h1 className="text-white text-5xl font-bold">SBA Loans</h1>
          </div>
        </div>
        
        {/* Main Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 mt-12 p-8">
          
          {/* Sidebar - Products */}
          <div className="lg:col-span-1">
            <h2 className="text-2xl font-semibold">Our Products</h2>
            <ul className="space-y-2 mt-4">
              <li><a href="/" className="text-blue-600 font-bold">SBA Loans</a></li>
              <li><a href="/lines-of-credit" className="text-blue-600">Lines of Credit</a></li>
              <li><a href="/equipment-financing" className="text-blue-600">Equipment Financing</a></li>
            </ul>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-3 grid grid-cols-1 lg:grid-cols-2 gap-8">
            
            {/* Loan Information */}
            <div>
              <h2 className="text-2xl font-semibold">Apply for a SBA loan in minutes</h2>
              <p className="mt-4 text-gray-600">
                AKFSI is your ally for small business financing. Our streamlined application process and committed team make the process of applying for an SBA loan effortless. Throughout the journey of completing our online application, our financial experts stand with you every step of the way. By leveraging the capabilities of AI technology and skilled professionals, we’ve simplified the approval process.
              </p>
              <h3 className="mt-6 font-semibold">How Can You Use Your SBA Loan?</h3>
              <ul className="list-disc list-inside mt-2 text-gray-600">
                <li>Buy new equipment or inventory, hire new staff, or prepare for seasonal changes.</li>
                <li>Get extra working capital to manage payroll, bridge gaps in cash flow, or pay bills.</li>
                <li>Expand or open a second location, take on more clients, or capitalize on bulk order discounts.</li>
              </ul>
            </div>

                      <form onSubmit={handleSubmitForm} className="space-y-4 mt-4">
            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              {validationErrors.firstName && <p className="text-red-500">{validationErrors.firstName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
              {validationErrors.lastName && <p className="text-red-500">{validationErrors.lastName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="text"
                name="businessName"
                placeholder="Business Name"
                value={formData.businessName}
                onChange={handleChange}
                required
              />
              {validationErrors.businessName && <p className="text-red-500">{validationErrors.businessName}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="tel"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              {validationErrors.phone && <p className="text-red-500">{validationErrors.phone}</p>}
            </div>

            <div>
              <input
                className="w-full p-2 border rounded-md"
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              {validationErrors.email && <p className="text-red-500">{validationErrors.email}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="stateOfIncorporation"
                value={formData.stateOfIncorporation}
                onChange={handleChange}
                required
              >
                <option value="default">Select State</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NE">NE</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
              {validationErrors.stateOfIncorporation && <p className="text-red-500">{validationErrors.stateOfIncorporation}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="yearOfIncorporation"
                value={formData.yearOfIncorporation}
                onChange={handleChange}
                required
              >
                <option value="default">Year of Incorporation</option>
                {
                  Array.from({ length: 2024 - 1990 + 1 }, (_, i) => (
                    <option key={2024 - i} value={2024 - i}>
                      {2024 - i}
                    </option>
                  ))
                }
              </select>
              {validationErrors.yearOfIncorporation && <p className="text-red-500">{validationErrors.yearOfIncorporation}</p>}
            </div>

            <div>
              <select
                className="w-full p-2 border rounded-md"
                name="ficoScore"
                value={formData.ficoScore}
                onChange={handleChange}
                required
              >
                <option value="">Select FICO Score</option>
                <option value="550-639">550-639</option>
                <option value="640-699">640-699</option>
                <option value="700-749">700-749</option>
                <option value="750+">750+</option>
              </select>
              {validationErrors.ficoScore && <p className="text-red-500">{validationErrors.ficoScore}</p>}
            </div>

            <button
              className="bg-blue-600 text-white p-2 rounded-md w-full"
              type="submit"
              disabled={loading}
            >
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </form>


          </div>
        </div>

        {/* Benefits Section */}
        <div className="mt-12 p-8 bg-gray-100">
          <h2 className="text-2xl font-semibold">Benefits</h2>
          <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div>
              <h3 className="text-lg font-semibold">Loan Amounts</h3>
              <p>$10,000 - $5,000,000</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">Rates</h3>
              <p>Starting at Prime +2.75%</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold">Repayment Terms</h3>
              <p>10 - 25 years</p>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mt-12 p-8">
          <h2 className="text-2xl font-semibold">Frequently Asked Questions</h2>
          <div className="mt-4 space-y-4">
            <details className="bg-white shadow rounded-md p-4">
              <summary className="font-semibold">What is an SBA loan?</summary>
              <p className="mt-2 text-gray-600">The SBA 7(a) loan is a loan program offered by the U.S. Small Business Administration (SBA) to provide small businesses with access to affordable financing. The program is designed to help small businesses that may not be able to obtain traditional financing from banks or other lending institutions.<br /><br />Under the SBA 7(a) loan program, the SBA does not directly lend money to small businesses. Instead, the SBA guarantees a portion of the loan that is made by a participating lender. This guarantee reduces the risk to the lender, making it easier for small businesses to obtain financing.<br /><br />SBA 7(a) loans can be used for a wide range of business purposes, including working capital, equipment purchases, real estate acquisitions, and debt refinancing. The maximum loan amount for an SBA 7(a) loan is $5 million, and the repayment terms can vary depending on the purpose of the loan.<br /><br />Small businesses that are eligible for SBA 7(a) loans include those that are for-profit, have a physical presence in the United States, and meet certain size requirements. The SBA also considers factors such as the business owner's credit score, the purpose of the loan, and the borrower's ability to repay the loan when evaluating loan applications.</p>
            </details>
            <details className="bg-white shadow rounded-md p-4">
              <summary className="font-semibold">What can you use your SBA loan for?</summary>
              <p className="mt-2 text-gray-600">
                The Small Business Administration (SBA) 7(a) loan program is a popular loan program for small businesses in the United States. The loan can be used for a wide range of business purposes, including:
              </p>
              <ol className="mt-4 ml-4 text-gray-600 list-decimal space-y-2">
                <li><strong>Working capital:</strong> SBA 7(a) loans can be used to finance ongoing business expenses, such as rent, payroll, utilities, and inventory.</li>
                <li><strong>Business acquisition:</strong> You can use an SBA 7(a) loan to buy an existing business or franchise.</li>
                <li><strong>Equipment purchases:</strong> You can use an SBA 7(a) loan to buy equipment, machinery, or other assets needed to run your business.</li>
                <li><strong>Real estate purchases:</strong> SBA 7(a) loans can be used to purchase commercial real estate or refinance existing real estate debt.</li>
                <li><strong>Construction and renovation:</strong> If you need to build or renovate a commercial property, an SBA 7(a) loan can help finance the project.</li>
                <li><strong>Debt refinancing:</strong> You can use an SBA 7(a) loan to refinance existing debt, such as business credit card debt or high-interest loans.</li>
              </ol>
            </details>
            <details className="bg-white shadow rounded-md p-4">
              <summary className="font-semibold">What are the benefits of an SBA Loan?</summary>
              <p className="mt-2 text-gray-600">
                The SBA 7(a) loan program offers a number of benefits for small businesses, including:
              </p>
              <ol className="mt-4 ml-4 text-gray-600 list-decimal space-y-2">
                <li><strong>Access to funding:</strong> SBA 7(a) loans provide small businesses with access to capital that may not be available through traditional lending sources.</li>
                <li><strong>Lower down payments:</strong> SBA 7(a) loans require lower down payments than many traditional loans, which can make it easier for small businesses to access financing.</li>
                <li><strong>Longer repayment terms:</strong> SBA 7(a) loans typically have longer repayment terms than traditional loans, which can help small businesses manage their cash flow.</li>
                <li><strong>Lower interest rates:</strong> SBA 7(a) loans often have lower interest rates than traditional loans, which can save small businesses money over the life of the loan.</li>
                <li><strong>Flexibility:</strong> SBA 7(a) loans can be used for a variety of business purposes, including working capital, equipment purchases, and debt refinancing, giving small businesses greater flexibility in how they use the funds.</li>
                <li><strong>No collateral required:</strong> SBA 7(a) loans may be available without requiring collateral from the borrower, which can be especially beneficial for businesses that do not have significant assets to pledge as collateral.</li>
                <li><strong>Counseling and training:</strong> The SBA offers free counseling and training to small business owners who receive an SBA 7(a) loan, which can help them grow their businesses and improve their chances of success.</li>
              </ol>
              <p className="mt-4 text-gray-600">
                Overall, the SBA 7(a) loan program is a valuable resource for small businesses that need access to capital to grow and expand.
              </p>
            </details>

          </div>
        </div>

        {/* Footer Section */}
        <div className="mt-12 p-8 bg-gray-200 text-center">
          <p>Keep up to date — get updates with the latest topics.</p>
          <form className="mt-4">
            <input className="p-2 border rounded-md" type="email" placeholder="Your email address" />
            <button className="bg-blue-600 text-white p-2 rounded-md">Sign me up!</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SBAFundingPage;
