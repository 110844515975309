import React from 'react'
import HeroSection from '../components/HeroSection'
import ProductsSection from '../components/Products'
import IndustriesSection from '../components/Industries'
import RequestCallBack from '../components/RequestCallback'
import ContactUsSection from '../components/ContactUs'

const Home = () => {
	return (
		<div>
			<HeroSection />
      <ProductsSection />
      <IndustriesSection />
      <RequestCallBack />
      <ContactUsSection />
		</div>
	)
}

export default Home