import React from 'react';
import { BiSolidBank } from 'react-icons/bi';
import { FaMoneyBillAlt, FaTruck } from 'react-icons/fa';

const ProductsSection = () => {
  return (
    <div className="max-w-7xl mx-auto py-12 px-4 md:px-8">
      <div className="grid grid-cols-1 md:grid-cols-[30%,70%] gap-8">
        {/* Left Side - Our Products List */}
        <div className="space-y-4">
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-900">
            Our Products
          </h2>
          <ul className="space-y-2 text-base md:text-lg">
            <li>
              <a
                href="/sba-loans"
                className="text-gray-600 hover:text-blue-600"
              >
                SBA Loans
              </a>
            </li>
            <li>
              <a
                href="/lines-of-credit"
                className="text-gray-600 hover:text-blue-600"
              >
                Lines of Credit
              </a>
            </li>
            <li>
              <a
                href="/equipment-financing"
                className="text-gray-600 hover:text-blue-600"
              >
                Equipment Financing
              </a>
            </li>
          </ul>
        </div>

        {/* Right Side - Helping your business grow */}
        <div>
          <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 text-center mb-8">
            Helping your business grow, in every financial situation.
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {/* SBA Loans */}
            <div
              className="relative flex flex-col items-center bg-no-repeat bg-center p-6 bg-cover"
              style={{
                backgroundImage:
                  'url(https://consulting.vamtam.com/wp-content/uploads/2017/08/shape-bg.svg)',
              }}
            >
              <FaMoneyBillAlt size={50} className="text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-semibold text-gray-900">
                SBA Loans
              </h3>
              <a href="/sba-loans" className="text-blue-600 mt-2 underline">
                Read more
              </a>
            </div>

            {/* Lines of Credit */}
            <div
              className="relative flex flex-col items-center bg-no-repeat bg-center p-6 bg-cover"
              style={{
                backgroundImage:
                  'url(https://consulting.vamtam.com/wp-content/uploads/2017/08/shape-bg.svg)',
              }}
            >
              <BiSolidBank size={50} className="text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-semibold text-gray-900">
                Lines of Credit
              </h3>
              <a
                href="/lines-of-credit"
                className="text-blue-600 mt-2 underline"
              >
                Read more
              </a>
            </div>

            {/* Equipment Financing */}
            <div
              className="relative flex flex-col items-center bg-no-repeat bg-center p-6 bg-cover"
              style={{
                backgroundImage:
                  'url(https://consulting.vamtam.com/wp-content/uploads/2017/08/shape-bg.svg)',
              }}
            >
              <FaTruck size={50} className="text-blue-600 mb-4" />
              <h3 className="text-lg md:text-xl font-semibold text-gray-900">
                Equipment Financing
              </h3>
              <a
                href="/equipment-financing"
                className="text-blue-600 mt-2 underline"
              >
                Read more
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSection;
