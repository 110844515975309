import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaUsers } from 'react-icons/fa';

const ContactUsSection = () => {
  return (
    <div className="bg-blue-600 text-white py-12">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 relative">
        
        {/* Our Offices */}
        <div className="relative flex flex-col items-center p-6">
          <FaMapMarkerAlt className="text-white w-10 h-10 mb-4 z-10" />
          <h3 className="text-xl font-semibold z-10">Our Offices</h3>
          <p className="mt-2 text-center z-10">
            View our locations around the US and find contact details for your nearest office.
          </p>
          <a href="/contact" className="underline mt-4 block z-10">
            Locate the Nearest Office
          </a>
          {/* Background icon */}
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20">
            <FaMapMarkerAlt className="text-white w-40 h-40" />
          </div>
        </div>

        {/* Contact Us */}
        <div className="relative flex flex-col items-center p-6">
          <FaEnvelope className="text-white w-10 h-10 mb-4 z-10" />
          <h3 className="text-xl font-semibold z-10">Contact Us</h3>
          <p className="mt-2 text-center z-10">
            You may contact us anytime by emailing us at contact@akfsi.com.
          </p>
          <a href="/contact" className="underline mt-4 block z-10">
            Contact Us
          </a>
          {/* Background icon */}
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20">
            <FaEnvelope className="text-white w-40 h-40" />
          </div>
        </div>

        {/* Careers */}
        <div className="relative flex flex-col items-center p-6">
          <FaUsers className="text-white w-10 h-10 mb-4 z-10" />
          <h3 className="text-xl font-semibold z-10">Careers</h3>
          <p className="mt-2 text-center z-10">
            Join one of the largest commercial lenders in the US with a unique position.
          </p>
          <a href="/contact" className="underline mt-4 block z-10">
            See Open Positions
          </a>
          {/* Background icon */}
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-20">
            <FaUsers className="text-white w-40 h-40" />
          </div>
        </div>

      </div>
    </div>
  );
};

export default ContactUsSection;
