import { Route, Router } from "wouter";
import ContactUsSection from "./components/ContactUs";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./pages/Home";
import SBAFundingPage from "./pages/SBAFunding";
import LinesOfCreditPage from "./pages/LinesOfCredit";
import EquipmentFinancingPage from "./pages/EquipmentFinancing";
import ContactUsPage from "./pages/ContactUs";
const App = () => {
  return (
    <div className="app">
      <Header />
      <Router>
        <Route path='/' component={Home} />
        <Route path='/sba-loans' component={SBAFundingPage} />
        <Route path='/lines-of-credit' component={LinesOfCreditPage} />
        <Route path='/equipment-financing' component={EquipmentFinancingPage} />
        <Route path='/contact-us' component={ContactUsPage} />
      </Router>
      <Footer />
    </div>
  );
}

export default App;
